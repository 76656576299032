#root {
    color: white;
    background-color: rgb(57, 4, 57);
}

h1 {
    font-family: 'Matura MT Script Capitals';
}

.dance {
    color: rgb(220, 199, 240);
}

.navText {
    font-family: 'Arial', sans-serif;
}